<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="updatePwdValidation">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider
                    #default="{ errors }"
                    name="旧密码"
                    rules="required|min:8"
            >
            <b-form-group
                    label="旧密码"
                    label-for="account-old-password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                        id="account-old-password"
                        v-model="passwordValueOld"
                        name="old-password"
                        :state="errors.length > 0 ? false : null"
                        :type="passwordFieldTypeOld"
                        placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                          :icon="passwordToggleIconOld"
                          class="cursor-pointer"
                          @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
                    #default="{ errors }"
                    name="新密码"
                    rules="required|min:8"
            >
              <b-form-group
                      label-for="account-new-password"
                      label="新密码"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                          :state="errors.length > 0 ? false : null"
                          id="account-new-password"
                          v-model="newPasswordValue"
                          :type="passwordFieldTypeNew"
                          name="new-password"
                          placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                            :icon="passwordToggleIconNew"
                            class="cursor-pointer"
                            @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-col>
          <!--/ new password -->
        </b-row>
        <b-row>
          <!-- retype password -->
          <b-col md="6">
            <validation-provider
                    #default="{ errors }"
                    name="确认新密码"
                    rules="required|min:8"
            >
              <b-form-group
                      label-for="account-retype-new-password"
                      label="确认新密码"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                          :state="errors.length > 0 ? false : null"
                          id="account-retype-new-password"
                          v-model="RetypePassword"
                          :type="passwordFieldTypeRetype"
                          name="retype-password"
                          placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                            :icon="passwordToggleIconRetype"
                            class="cursor-pointer"
                            @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-1 mr-1"
                    @click="submitData"
            >
              保存修改
            </b-button>
            <b-button
                    @click="resetPwd"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    class="mt-1"
            >
              重置
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { UpdateCurrentUserPassword  } from '@/api/system/user/user'
import { error, success } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetPwd() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    submitData(){
      this.$refs.updatePwdValidation.validate().then(validate => {
        if (validate) {
          const submitForm = {
            oldPwd: this.passwordValueOld,
            newPwd : this.newPasswordValue,
            ReNewPwd : this.RetypePassword,
          }
          UpdateCurrentUserPassword(submitForm).then(res=>{
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              setTimeout(()=>{
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
                localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
                // Remove userData from localStorage
                localStorage.removeItem('userData')
                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              },1000)
            } else {
              error(resData.msg)
            }
          })
        }
      })
    }
  },
}
</script>
