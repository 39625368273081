<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="uploadFileClick"
        >
          上传头像
        </b-button>
        <input
          id="uploadFileInput"
          type="file"
          accept="image/*"
          style="display: none"
          @change="uploadFileInputChange"
        >
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="optionsLocal.avatar = oldAvatar"
        >
          重置
        </b-button>
        <!--/ reset -->
        <b-card-text>只接受 JPG, GIF 和 PNG. 最大尺寸 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="登陆名"
            label-for="account-username"
          >
            <b-form-input
              :value="optionsLocal.account"
              placeholder="Username"
              name="username"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="部门"
            label-for="account-department"
          >
            <b-form-input
              :value="optionsLocal.dept_str"
              name="department"
              placeholder="部门"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="用户名"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.actualName"
              disabled
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="手机号码"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.mobile"
              name="mobile"
              placeholder="mobile"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="submitData"
          >
            保存修改
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            重置
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { UpdateCurrentUserInfo, GetCurrentUserInfo } from '@/api/system/user/user'
import { error, success, enterpriseEvent } from '@core/utils/utils'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //
    const optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    console.log(optionsLocal)
    return {
      optionsLocal: {},
      oldAvatar: optionsLocal.avatar,
      profileFile: null,
    }
  },
  created() {
    this.getCurrentUserInfo()
  },
  methods: {
    getCurrentUserInfo() {
      GetCurrentUserInfo().then(res=>{
        const resData = res.data
        if (resData.code === 0) {
          this.optionsLocal = resData.data
        }
      })
    },
    uploadFileClick() {
      document.getElementById('uploadFileInput').click()
    },
    uploadFileInputChange() {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      // this.form.fileName = fileVal[0].name
      const that = this
      const file = fileVal[0]
      //   console.log(file);
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        //   console.log(e);
        that.optionsLocal.avatar = this.result
      }
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    submitData() {
      const uploadDom = document.getElementById('uploadFileInput')
      const formData = new FormData()
      formData.append('avatarFile', uploadDom.files[0])
      formData.append('email', this.optionsLocal.email)
      formData.append('nickName', this.optionsLocal.nickName)
      formData.append('mobile', this.optionsLocal.mobile)
      UpdateCurrentUserInfo(formData).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          localStorage.setItem('userData', JSON.stringify(this.optionsLocal))
          enterpriseEvent.$emit('updateUserInfo', () => {
          })
        } else {
          error(resData.msg)
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
